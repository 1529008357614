import { Box, Grid, Heading, VStack, Link } from '@chakra-ui/react'
import { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useApplicationContext } from '@/context/ApplicationContext'
import { MyApplications } from '@/features/MyApplications'
import { IntroBox, ManualApplicationBox } from './Home'
import { Widget } from '@/components/Widget'
import { MultilineText } from '@/components/MultilineText'
import { externalLinks } from '@/lib/externalLinks'

export const HomePage: FC = () => {
  const { t } = useTranslation()
  const {
    state: { applicant },
  } = useApplicationContext()

  return (
    <VStack gap={4} w={'100%'} alignItems={'flex-start'}>
      <IntroBox applicant={applicant} />
      {applicant && (
        <>
          <VStack gap={4} alignItems={'flex-start'} w={'full'}>
            <Grid
              width={'100%'}
              gridTemplateColumns={{
                base: '1fr',
                // Commented out until we have the automatic application feature
                md: '1fr 1fr',
              }}
              gap={4}
            >
              {/* // Commented out until we have the automatic application feature */}
              {/* <AutomaticApplicationBox /> */}
              <ManualApplicationBox />
              <LastApplicationBox />
            </Grid>
          </VStack>
          <MyApplications emptyText={t('newApplicationPage.previousApplications.noActiveApplications.label')} />
        </>
      )}
    </VStack>
  )
}
export type HomePageProps = ComponentProps<typeof HomePage>

const LastApplicationBox = () => {
  const { t } = useTranslation()
  return (
    <Widget>
      <Box height={'full'} maxW={'80ch'}>
        <Heading variant={'widgetSmall'}>{t('privacyBox.title')}</Heading>
        <MultilineText
          text={t('privacyBox.content')}
          textProps={{
            variant: 'widgetSmall',
            fontSize: '1rem',
          }}
        />
        <Link href={externalLinks.privacyAgreement} isExternal>
          {t('privacyBox.readMore')}
        </Link>
      </Box>
    </Widget>
  )
}
