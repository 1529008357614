import { createEnv } from '@t3-oss/env-core'
import { z } from 'zod'

export const env = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_API_URL: z.string().url(),
    VITE_LOCIZE_PROJECTID: z.string(),
    VITE_LOCIZE_API_KEY: z.string().optional(),
    VITE_BANK_ID_LOGIN_URL: z.string().url(),
  },
  runtimeEnv: import.meta.env,
})
